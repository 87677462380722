import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useStoreState } from '../store';
import { GET_PLAYER } from '../utils/queries';
import { Participation } from './useLeaguesState';

export interface PlayerInfo {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  playerID: string;
  phoneNumber: string;
  bio: string;
  kitSize: string;
  isCaptain: boolean;
  paid: boolean;
  verified: boolean;
  photo: {
    url: string;
  };
  nationalIdImage: {
    url: string;
  };
  participation: Participation[];
  age: number;
}

export default function usePlayerInfo() {
  const playerInfoItem = localStorage.getItem('playerInfo');
  const { userModel: userState } = useStoreState((state) => state);
  const pInfo: PlayerInfo = playerInfoItem
    ? JSON.parse(playerInfoItem)
    : userState.playerInfo;

  const { data: playerData } = useQuery(GET_PLAYER, {
    variables: { id: pInfo?.id },
    pollInterval: 20_000,
  });

  return useMemo(() => {
    return playerData?.player as PlayerInfo;
  }, [playerData]);
}
