import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  Image,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState, useRef, ReactNode } from 'react';
import { PlayerInterface } from '../containers/Player';
import { SERVER_URL } from '../utils/constants';
import { isElite, mapKeyToName, timeout } from '../utils/helper';
import { CREATE_PARTICIPATION, UPDATE_PLAYER } from '../utils/mutations';
import { kitSize } from '../utils/Select';
import UserIcon from '../assets/user.png';
import { GET_PARTICIPATION_BY_NAME, GET_TEAM_BY_NAME } from '../utils/queries';
import useLeaguesState from '../hooks/useLeaguesState';

const PlayerData = ({
  img,
  size,
  player,
  jerseyNumbers,
  children,
  isCaptain,
  comp,
}: {
  img: string;
  size?: string;
  player?: PlayerInterface;
  jerseyNumbers?: number[];
  children?: ReactNode;
  isCaptain?: boolean;
  comp?: string;
}) => {
  const toast = useToast();
  const [updatePlayer] = useMutation(UPDATE_PLAYER);
  const [fetchParticipation] = useLazyQuery(GET_PARTICIPATION_BY_NAME);
  const [fetchTeam] = useLazyQuery(GET_TEAM_BY_NAME);
  const [createParticipation] = useMutation(CREATE_PARTICIPATION);
  const { currentParticipation, currentSeason, currentCompetition } =
    useLeaguesState(comp);
  const [kitSizeValue, setKitSizeValue] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [numberValue, setNumberValue] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const handleRemovePlayer = async () => {
    if (!player || !currentParticipation) return;
    const msg = 'Are you sure you want to remove this player from your team ?!';
    // eslint-disable-next-line no-restricted-globals
    if (confirm(msg)) {
      try {
        toast({
          title: `Removing player...`,
          status: 'loading',
          isClosable: true,
        });
        const appriviateCompetitionName = `${
          isElite(currentCompetition?.name)
            ? ''
            : ' ' + mapKeyToName(currentCompetition?.name)
        }`;
        const tfMarketName = `${currentSeason?.name} Transfer Market${appriviateCompetitionName}`;

        let pRes: any;
        pRes = await fetchParticipation({
          variables: { name: tfMarketName },
        });

        if (!pRes?.data?.participation) {
          toast({
            title: `Creating a new Transfer Market team because no transfer team found for this season...`,
            status: 'loading',
            isClosable: true,
          });

          const teamRes = await fetchTeam({
            variables: {
              name: 'Transfer Market',
            },
          });
          if (!teamRes?.data?.team) {
            toast({
              title: `No team found`,
              status: 'error',
              isClosable: true,
            });
            return;
          }
          let participationData = {
            name: tfMarketName,
            seasons: { connect: [{ id: currentSeason?.id }] },
            teams: { connect: [{ id: teamRes?.data?.team?.id }] },
            competition: { connect: { id: currentCompetition?.id } },
            isVerified: false,
          };
          if (!currentCompetition?.id) {
            toast({
              title: `No competition found`,
              status: 'error',
              isClosable: true,
            });
            return;
          }
          const res = await createParticipation({
            variables: { data: participationData },
          });

          if (res?.data?.createParticipation?.id) {
            toast({
              title: `Transfer Market team created successfully!`,
              status: 'success',
              isClosable: true,
            });

            pRes = {
              data: {
                participation: { id: res?.data?.createParticipation?.id },
              },
            };
          }
        }
        const participationId = pRes.data.participation.id;
        const updatedPlayerData = {
          participation: {
            disconnect: [{ id: currentParticipation?.id }],
            connect: [{ id: participationId }],
          },
        };
        await updatePlayer({
          variables: { id: player.id, data: updatedPlayerData },
        });
        onClose();
        await timeout(2000);
        toast({
          title: `Player removed successfully!`,
          status: 'success',
          isClosable: true,
        });
        await timeout(500);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSaveChanges = async () => {
    if (!player) return;

    let values = {
      kitSize: player.kitSize,
      jerseyNumber: player.jerseyNumber,
      jerseyName: player.jerseyName,
    };
    if (kitSizeValue) {
      values = { ...values, kitSize: kitSizeValue };
    }
    if (nameValue) {
      values = { ...values, jerseyName: nameValue };
    }
    if (numberValue) {
      values = { ...values, jerseyNumber: Number(numberValue) };
    }

    try {
      toast({
        title: `Updating player data...`,
        status: 'loading',
        isClosable: true,
      });
      await updatePlayer({ variables: { id: player.id, data: values } });

      onClose();
      setKitSizeValue('');
      setNameValue('');
      setNumberValue('');
      await timeout(2000);
      toast({
        title: `Player updated successfully!`,
        status: 'success',
        isClosable: true,
      });
      await timeout(500);
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast({
        title: error.message,
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      {children ? (
        <Box onClick={() => onOpen()} cursor="pointer">
          {children}
        </Box>
      ) : (
        <Image
          src={SERVER_URL + img}
          boxSize={size || '120px'}
          objectFit="cover"
          objectPosition={'top left'}
          m="0 auto"
          onClick={() => onOpen()}
          cursor="pointer"
          fallbackSrc={UserIcon}
        />
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody mt="20px">
              <Image
                src={SERVER_URL + img}
                boxSize="270px"
                objectFit="cover"
                objectPosition={'top left'}
                m="0 auto"
                fallbackSrc={UserIcon}
              />
              {isCaptain && player && jerseyNumbers ? (
                <Box>
                  <TableContainer mt="20px">
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th>Kit Size</Th>
                          <Th>Name</Th>
                          <Th isNumeric>Number</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>
                            <Select
                              width={'120px'}
                              value={kitSizeValue || player.kitSize}
                              onChange={(e) => {
                                setKitSizeValue(e.target.value);
                              }}
                            >
                              {kitSize.map((k: string, index) => {
                                return (
                                  <option value={k} key={k + String(index)}>
                                    {k}
                                  </option>
                                );
                              })}
                            </Select>
                          </Td>
                          <Td>
                            <Input
                              type="text"
                              value={nameValue || player.jerseyName}
                              onChange={(e) => {
                                setNameValue(e.target.value);
                              }}
                            />
                          </Td>
                          <Td isNumeric>
                            <Select
                              width={'80px'}
                              value={numberValue || player.jerseyNumber}
                              onChange={(e) => {
                                setNumberValue(e.target.value);
                              }}
                            >
                              {[...jerseyNumbers, player.jerseyNumber].map(
                                (n: number) => {
                                  return (
                                    <option value={n} key={n}>
                                      {n}
                                    </option>
                                  );
                                }
                              )}
                            </Select>{' '}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>

                  <HStack justifyContent={'center'} mt="20px">
                    <Button
                      colorScheme="blue"
                      ref={cancelRef}
                      onClick={handleSaveChanges}
                      isDisabled={
                        (!nameValue && !numberValue && !kitSizeValue) ||
                        currentCompetition?.disableEdits
                      }
                    >
                      Save Changes
                    </Button>
                    {player.isCaptain ? null : (
                      <Button
                        colorScheme="red"
                        ref={cancelRef}
                        onClick={handleRemovePlayer}
                        isDisabled={currentCompetition?.disableEdits}
                      >
                        Remove this player
                      </Button>
                    )}
                  </HStack>
                </Box>
              ) : null}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} m="0 auto">
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default PlayerData;
