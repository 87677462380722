import { Avatar, Box, Center, Flex, HStack, Image } from '@chakra-ui/react';
import { SERVER_URL } from '../../utils/constants';
import BlueLogo from '../../assets/theme-icon.png';
import { Link } from 'react-router-dom';
import { TableType } from '../../utils/types';
import { returnImage } from '../../utils/helper';
import { orderedTable } from '../../hooks/useLeagueTables';

export interface Team {
  id: string;
  name: string;
  teamLogo: {
    url: string;
  };
  mascotLogo: string;
}
export interface TDataType {
  id: string;
  name: string;
  teams: Array<Team>;
  tableData: TableType;
}
export interface StandingProps {
  groups: {
    [key: string]: Array<TableType>;
  };
  fallbackLogo?: string;
  isFull?: boolean;
  removeGD?: boolean;
  removeFP?: boolean;
  removeLinks?: boolean;
  selectedComp?: { name: string };
}

const LeagueTable = ({
  groups,
  isFull,
  removeFP,
  removeGD,
  removeLinks,
  fallbackLogo,
  selectedComp,
}: StandingProps) => {
  return (
    <>
      {groups ? (
        Object.keys(groups)
          ?.sort()
          ?.map((key: string) => {
            return (
              <Box mb="20px" key={key + 'teams-table'}>
                <Box
                  p="5px 5px 5px 10px"
                  fontSize="14px"
                  mb="10px"
                  background="gray.100"
                >
                  <Flex justifyContent="space-between">
                    <Box fontWeight={'bold'}>
                      {key !== 'null' ? `Group - ${key}` : 'Team'}
                    </Box>

                    <HStack spacing="0" fontSize="12px">
                      <Box w="30px" textAlign={'center'}>
                        PLD
                      </Box>
                      {isFull ? (
                        <>
                          <Box w="30px" textAlign={'center'}>
                            W
                          </Box>
                          <Box w="30px" textAlign={'center'}>
                            L
                          </Box>
                          <Box w="30px" textAlign={'center'}>
                            D
                          </Box>
                        </>
                      ) : null}

                      {removeGD ? null : (
                        <Box w="30px" textAlign={'center'}>
                          +/-
                        </Box>
                      )}
                      {isFull && !removeFP ? (
                        <Box w="30px" textAlign={'center'}>
                          FP
                        </Box>
                      ) : null}
                      <Box w="30px" textAlign={'center'} fontWeight={'bold'}>
                        PTS
                      </Box>
                    </HStack>
                  </Flex>
                </Box>
                {orderedTable(groups[key])?.length &&
                  orderedTable(groups[key])?.map((p: any) => {
                    const team = p?.participation?.teams[0];
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [_f, _s, ...rest] = p.team
                      ?.split(' ')
                      .filter(Boolean);
                    const joinedName = rest.join(' ');
                    const teamName = selectedComp
                      ? joinedName?.includes('EAL')
                        ? joinedName.replace(`E${selectedComp}`, '')
                        : joinedName.replace(selectedComp, '')
                      : joinedName;

                    const tableData = p;
                    return (
                      <Box key={p.id + 'league table' + Math.random()}>
                        <Link
                          to={`/team/${p.partId}`}
                          style={{
                            pointerEvents: removeLinks ? 'none' : 'auto',
                          }}
                        >
                          <HStack
                            alignItems="center"
                            justifyContent="space-between"
                            cursor="pointer"
                            _hover={{ bg: 'teal.50', color: 'inherit' }}
                          >
                            <HStack alignItems="center">
                              <Flex
                                boxSize={{ base: '35px', md: '50px' }}
                                justifyContent="center"
                                alignItems="center"
                                fontSize="12px"
                                textAlign="center"
                              >
                                <Box>
                                  {returnImage(team) ? (
                                    <Image
                                      src={SERVER_URL + returnImage(team)}
                                      boxSize={{ base: '35px', md: '50px' }}
                                      objectFit="cover"
                                      m="0 auto"
                                      cursor="pointer"
                                      fallbackSrc={fallbackLogo ?? BlueLogo}
                                    />
                                  ) : (
                                    <Avatar
                                      boxSize={{ base: '35px', md: '50px' }}
                                      name={`${team?.name ?? teamName} `}
                                      src={''}
                                    />
                                  )}
                                </Box>
                              </Flex>

                              <Flex
                                flexDirection="column"
                                h="58px"
                                justifyContent="center"
                              >
                                <Flex
                                  noOfLines={[1, 1, 2, 2]}
                                  fontSize={'14px'}
                                  fontWeight={'bold'}
                                  alignItems="center"
                                  textTransform={'capitalize'}
                                  maxW={['83px', '83px', 'auto', 'auto']}
                                  w={{ base: '80px', md: 'auto' }}
                                >
                                  {team?.name ?? teamName}
                                </Flex>
                              </Flex>
                            </HStack>
                            <Box px="5px">
                              {tableData ? (
                                <HStack spacing="0" fontSize="12px">
                                  <Box w="30px" textAlign={'center'}>
                                    {tableData.MP}
                                  </Box>
                                  {isFull ? (
                                    <>
                                      <Box w="30px" textAlign={'center'}>
                                        {tableData.W}
                                      </Box>
                                      <Box w="30px" textAlign={'center'}>
                                        {tableData.L}
                                      </Box>
                                      <Box w="30px" textAlign={'center'}>
                                        {tableData.D}
                                      </Box>
                                    </>
                                  ) : null}
                                  {removeGD ? null : (
                                    <Box w="30px" textAlign={'center'}>
                                      {tableData.GD}
                                    </Box>
                                  )}
                                  {isFull && !removeFP ? (
                                    <Box w="30px" textAlign={'center'}>
                                      {tableData.FP}
                                    </Box>
                                  ) : null}
                                  <Box
                                    w="30px"
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                  >
                                    {tableData.PTS}
                                  </Box>
                                </HStack>
                              ) : null}
                            </Box>
                          </HStack>
                        </Link>
                      </Box>
                    );
                  })}
              </Box>
            );
          })
      ) : (
        <Center>Not available</Center>
      )}
    </>
  );
};

export default LeagueTable;
