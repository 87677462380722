import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { BiCamera } from 'react-icons/bi';

import { useNavigate } from 'react-router-dom';
import FileUpload from '../../components/FileUpload';
import usePlayerInfo from '../../hooks/usePlayerInfo';
import { useStoreActions, useStoreState } from '../../store';
import { paymentLinks, SERVER_URL } from '../../utils/constants';
import {
  isBrowser,
  SmoothVerticalScrolling,
  timeout,
} from '../../utils/helper';
import { CHANGE_PASSWORD, UPDATE_PLAYER } from '../../utils/mutations';
import { GET_PLAYER } from '../../utils/queries';
import { kitSize } from '../../utils/Select';
import { greenishColor } from '../../utils/theme';
import Footer from '../Footer';
import Sidebar from '../Navigation';
import EditBox from './EditBox';
import SelectBox from './SelectBox';

const Account = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [edits, setEidts] = useState<any>({
    phoneNumber: '',
    bio: '',
    kitSize: '',
  });
  const toast = useToast();
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const [updatePlayer] = useMutation(UPDATE_PLAYER);
  const [getPlayer] = useLazyQuery(GET_PLAYER);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const navigate = useNavigate();
  const playerInfo = usePlayerInfo();
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);
  if (!playerInfo) {
    navigate('/login');
  }

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);
  const refs: any = {};
  const handleSideBarClick = (id: string) => {
    if (refs) {
      const selectedRef = refs[id];
      if (isBrowser && selectedRef) {
        SmoothVerticalScrolling(selectedRef, 200, 'top');
      }
    }
  };
  const currentTeam = useMemo(() => {
    if (!playerInfo) return null;
    if (!playerInfo.participation.length) {
      return 'N/A';
    }
    const currentSeason =
      playerInfo?.participation[playerInfo.participation.length - 1].seasons[0]
        .name;
    const currentTeam =
      playerInfo?.participation[playerInfo.participation.length - 1].teams[0]
        .name;
    return `${currentSeason} ${currentTeam}`;
  }, [playerInfo]);

  const isMatch = useMemo(() => {
    return newPassword === repeatedPassword;
  }, [newPassword, repeatedPassword]);

  const handleChangePassword = async () => {
    setError(false);
    setErrMsg('');
    try {
      if (!playerInfo) return;
      if (!oldPassword) {
        setError(true);
        setErrMsg('Please enter your old password');
        return;
      }
      if (!newPassword) {
        setError(true);
        setErrMsg('Please enter your new password');
        return;
      }
      if (!repeatedPassword) {
        setError(true);
        setErrMsg('Please repeat your new password');
        return;
      }
      if (!isMatch) {
        setError(true);
        setErrMsg('New passwords do not match');
        return;
      }
      const res = await changePassword({
        variables: {
          email: playerInfo?.email.toLowerCase(),
          oldPassword,
          newPassword,
        },
      });
      if (
        res.data &&
        res.data.changePlayerPassword &&
        res.data.changePlayerPassword.id
      ) {
        toast({
          title: `Password changed successfully ✌️`,
          status: 'success',
          isClosable: true,
        });
        localStorage.removeItem('playerInfo');
        await timeout(2000);
        navigate(`/login`);
      } else {
        setError(true);
        setErrMsg('Something went wrong');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditBio = async (val: string) => {
    if (!val) return;
    setEidts({ ...edits, bio: val });
    await handleUpdatePlayerData('bio', val);
  };
  const handleEditPhoneNumber = async (val: string) => {
    if (!val) return;
    setEidts({ ...edits, phoneNumber: val });
    await handleUpdatePlayerData('phoneNumber', val);
  };
  const handleEditKitSize = async (val: string) => {
    if (!val) return;
    setEidts({ ...edits, kitSize: val });
    await handleUpdatePlayerData('kitSize', val);
  };

  const handleUpdatePlayerData = async (
    type: 'phoneNumber' | 'nationalIdImage' | 'bio' | 'kitSize',
    val?: string | null,
    files?: File[]
  ) => {
    try {
      if (!playerInfo) return;
      toast({
        title: `Updating player data...`,
        status: 'loading',
        isClosable: true,
      });
      let updatedPlayerData: any = {};
      if (type !== 'nationalIdImage') {
        if (!val) return;
        updatedPlayerData[type] = val;
      }
      if (type === 'nationalIdImage') {
        if (!files?.length) return;
        updatedPlayerData = {
          nationalIdImage: {
            upload: files[0],
          },
        };
      }
      if (!updatedPlayerData) return;
      onClose();
      await updatePlayer({
        variables: {
          id: playerInfo?.id,
          data: updatedPlayerData,
        },
      });
      await timeout(2000);
      const res = await getPlayer({ variables: { id: playerInfo.id } });
      if (res && res?.data) {
        const values = res?.data?.player ?? res?.data;
        const newPlayerInfo = values;
        localStorage.setItem('playerInfo', JSON.stringify(newPlayerInfo));
      }
      toast({
        title: `Player Data updated successfully!`,
        status: 'success',
        isClosable: true,
      });
      await timeout(1000);
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast({
        description: 'Something went wrong!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleSignout = () => {
    localStorage.removeItem('playerInfo');
    window.location.href = '/';
  };

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt={{ base: '125px', md: '200px' }}
        display="flex"
        justifyContent={'space-between'}
      >
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change Password</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <FormControl id="oldPassword" isInvalid={error}>
                  <FormLabel>Current Password</FormLabel>
                  <Input
                    type="password"
                    value={oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                      setError(false);
                    }}
                  />
                </FormControl>

                <FormControl id="newPassword" isInvalid={error}>
                  <FormLabel>New Password</FormLabel>
                  <Input
                    type="password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setError(false);
                    }}
                  />
                </FormControl>

                <FormControl id="repeatedPassword" isInvalid={error}>
                  <FormLabel>Repeat Password</FormLabel>
                  <Input
                    type="password"
                    value={repeatedPassword}
                    onChange={(e) => {
                      setRepeatedPassword(e.target.value);
                      setError(false);
                    }}
                  />

                  {error ? <FormErrorMessage>{errMsg}</FormErrorMessage> : null}
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                bg={greenishColor}
                color={'white'}
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
              <Button variant="ghost" onClick={handleChangePassword}>
                Change Password
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Flex
          alignItems="center"
          maxW="1200px"
          padding="0 20px"
          w="100%"
          margin="50px auto 20px auto"
        >
          <Box
            w="270px"
            display={['none', 'none', 'none', 'block']}
            position="fixed"
            top="240px"
            padding="0 20px"
          >
            <Heading as="h4" fontSize="25px">
              {'Settings'}
            </Heading>
            <List>
              <ListItem
                mt="25px"
                fontSize="18px"
                cursor="pointer"
                _hover={{ color: greenishColor }}
                onClick={() => handleSideBarClick('about')}
              >
                {'About me'}
              </ListItem>
              <ListItem
                mt="15px"
                fontSize="18px"
                cursor="pointer"
                _hover={{ color: greenishColor }}
                onClick={() => handleSideBarClick('payments')}
              >
                {'Payments'}
              </ListItem>
              <ListItem
                mt="15px"
                fontSize="18px"
                cursor="pointer"
                _hover={{ color: greenishColor }}
                onClick={() => handleSideBarClick('account')}
              >
                {'Account'}
              </ListItem>
            </List>

            <Box mt="30px">
              <Divider mb="10px" />
              <Box display="inline-block" fontSize="14px">
                {/* <Link href="/help">
            <Box cursor="pointer" _hover={{ color: 'teal.400' }}>
              {' '}
              {t('help')}
            </Box>
          </Link> */}
                <Link href="/season-details">
                  <Box cursor="pointer" _hover={{ color: greenishColor }}>
                    {'Season Details'}
                  </Box>
                </Link>
                <Link href="/league-rules">
                  <Box cursor="pointer" _hover={{ color: greenishColor }}>
                    {'League Rules'}
                  </Box>
                </Link>
                <Link href="/privacy">
                  <Box cursor="pointer" _hover={{ color: greenishColor }}>
                    {' '}
                    {'Transfer Market'}
                  </Box>
                </Link>
                <Link href="/terms">
                  <Box cursor="pointer" _hover={{ color: greenishColor }}>
                    {' '}
                    {'Contact us'}
                  </Box>
                </Link>
              </Box>
            </Box>
          </Box>

          <Box
            w={['100%', '100%', '100%', 'calc(100% - 290px)']}
            m={'0 0 0 auto'}
          >
            <Box>
              <Box ref={(ref) => (refs['about'] = ref)}>
                <Heading as="h4" fontSize="22px">
                  {'About me'}
                </Heading>
                <Divider mt="20px" />
              </Box>
              <Box mt="30px">
                <Heading as="h5" fontSize="20px">
                  {'Player Security Code'}
                </Heading>
                <Box
                  mt="20px"
                  fontSize="16px"
                  opacity="0.7"
                  color={greenishColor}
                >
                  <b>{playerInfo?.id}</b>
                </Box>
              </Box>
              <Box mt="40px">
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Box flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 45%']}>
                    <EditBox
                      title={'Player Name'}
                      initialValue={`${playerInfo?.firstName} ${playerInfo?.lastName}`}
                      handleEdit={() => {}}
                      disableEdit
                    />
                  </Box>
                  <Box flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 45%']}>
                    <EditBox
                      title={'Player ID'}
                      initialValue={`${playerInfo?.playerID}`}
                      handleEdit={() => {}}
                      disableEdit
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  mt="40px"
                >
                  <Box flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 45%']}>
                    <EditBox
                      title={'Email Address'}
                      initialValue={`${playerInfo?.email}`}
                      handleEdit={() => {}}
                      disableEdit
                    />
                  </Box>
                  <Box flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 45%']}>
                    <EditBox
                      title={'Current Team'}
                      initialValue={`${currentTeam}`}
                      handleEdit={() => {}}
                      disableEdit
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  mt="40px"
                >
                  <Box flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 45%']}>
                    <EditBox
                      title="Phone Number"
                      initialValue={`${playerInfo?.phoneNumber}`}
                      handleEdit={handleEditPhoneNumber}
                    />
                  </Box>
                  <Box flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 45%']}>
                    <SelectBox
                      items={kitSize}
                      title="Kit Size"
                      initialValue={`${playerInfo?.kitSize}`}
                      handleEdit={handleEditKitSize}
                    />
                  </Box>
                </Box>

                <Box mt="50px">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Heading as="h5" fontSize="20px">
                      {'National ID Image'}
                    </Heading>
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mt="30px"
                  >
                    <Box>
                      <Box fontSize="14px" opacity="0.7">
                        {
                          'Your real national ID image or you will not be allowed to enter your team matches'
                        }
                      </Box>
                    </Box>

                    <Box position="relative" role="group">
                      <Box
                        _groupHover={{ visibility: 'hidden', opacity: 0 }}
                        visibility="visible"
                        opacity={1}
                        transition="all 0.3s ease"
                        position="absolute"
                        bottom="0"
                        left="0"
                        w="100%"
                        height="50%"
                        bg={'rgba(0,0,0,0.4)'}
                        rounded="0 0 200px 200px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        zIndex="2"
                        cursor="pointer"
                      >
                        <Icon
                          as={BiCamera}
                          color="gray.200"
                          w="20px"
                          h="20px"
                        />
                      </Box>
                      <FileUpload
                        getImageFile={(files) => {
                          handleUpdatePlayerData(
                            'nationalIdImage',
                            null,
                            files
                          );
                        }}
                        preview={SERVER_URL + playerInfo?.nationalIdImage?.url}
                        width={'80px'}
                        height="80px"
                        maxW="100%"
                        placeholder={'Profile Image'}
                        borderRadius="50%"
                      />
                    </Box>
                  </Flex>
                </Box>

                <RouteLink
                  to={`/player/${playerInfo?.id}`}
                  state={{ private: true }}
                >
                  <Box
                    fontSize="14px"
                    mt={'15px'}
                    maxW="350px"
                    opacity="0.7"
                    _hover={{
                      color: greenishColor,
                      textDecoration: 'underline',
                    }}
                  >
                    {'Click here to view all player info'}
                  </Box>
                </RouteLink>
              </Box>

              <Box mt="50px">
                <Box>
                  <EditBox
                    title={'Bio'}
                    initialValue={playerInfo?.bio ?? ''}
                    handleEdit={handleEditBio}
                  />

                  <Box fontSize="14px" mt={'15px'} maxW="350px" opacity="0.7">
                    {'Your bio so teams can know more about you :)'}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box ref={(ref) => (refs['payments'] = ref)}>
              <Box mt="50px">
                <Heading as="h4" fontSize="22px">
                  {'Payments'}
                </Heading>
                <Divider mt="20px" />
                <Box>
                  {playerInfo.verified && playerInfo.paid ? (
                    'You are all set, Payment Complete!'
                  ) : (
                    <>
                      {playerInfo.isCaptain ? (
                        <Link
                          href={paymentLinks.team}
                          target="_blank"
                          textDecor="none !important"
                        >
                          <Button
                            variant="link"
                            textDecoration="underline"
                            fontWeight="normal"
                            color="inherit"
                            mt="10px"
                            _hover={{ color: greenishColor }}
                          >
                            {'Team Registration Payment Link'}
                          </Button>
                        </Link>
                      ) : null}
                      <Link
                        href={paymentLinks.player}
                        target="_blank"
                        textDecor="none !important"
                        ml="20px"
                      >
                        <Button
                          variant="link"
                          textDecoration="underline"
                          fontWeight="normal"
                          color="inherit"
                          mt="10px"
                          _hover={{ color: greenishColor }}
                        >
                          {'Player Registration Payment Link'}
                        </Button>
                      </Link>
                    </>
                  )}
                </Box>
                <Box fontSize="14px" mt="30px">
                  {`Player ${
                    playerInfo.isCaptain ? 'and team ' : ''
                  } registration payments for the latest season`}
                </Box>
              </Box>
            </Box>

            <Box ref={(ref) => (refs['account'] = ref)}>
              <Box mt="50px">
                <Heading as="h4" fontSize="22px">
                  {'Account'}
                </Heading>
                <Divider mt="20px" />
                <Box mt="30px">
                  <Heading as="h6" fontSize="18px">
                    {'Change Password'}
                  </Heading>
                  <Box fontSize="14px" mt="30px">
                    {
                      'This will log you out immediately when your change of password is successful'
                    }
                  </Box>
                  <Button
                    variant="link"
                    textDecoration="underline"
                    fontWeight="normal"
                    color="inherit"
                    mt="10px"
                    _hover={{ color: greenishColor }}
                    onClick={onOpen}
                  >
                    {'Change Password'}
                  </Button>
                  <Divider mt="20px" />
                </Box>
                <Box mt="30px">
                  <Heading as="h6" fontSize="18px">
                    {'Sign out'}
                  </Heading>
                  <Box fontSize="14px" mt="30px">
                    {'This will log you out and clear any cached data'}
                  </Box>
                  <Button
                    variant="link"
                    textDecoration="underline"
                    fontWeight="normal"
                    color="inherit"
                    mt="10px"
                    _hover={{ color: greenishColor }}
                    onClick={handleSignout}
                  >
                    {'Sign out'}
                  </Button>
                  <Divider mt="20px" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default Account;
