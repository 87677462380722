import {
  Box,
  Text,
  Flex,
  Image,
  Link,
  useMediaQuery,
  Center,
  Badge,
} from '@chakra-ui/react';
import { SERVER_URL } from '../../utils/constants';
import {
  returnImage,
  returnInMatchTime,
  returnTime,
  returnTimeAnyway,
} from '../../utils/helper';

import {
  ButtonBack,
  ButtonNext,
  Carousel,
  Slide,
  Slider,
} from 'react-scroll-snap-anime-slider';
import useMatches from '../../hooks/useMatches';
import dayjs from 'dayjs';
import { greenishColor } from '../../utils/theme';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ThemeIcon from '../../assets/theme-icon.png';
import { OrderDirection } from '../../__generated__/graphql';
import { useMemo } from 'react';

const fourteenDaysAhead = dayjs().add(21, 'day').toISOString();
const sevenDaysAgo = dayjs().subtract(7, 'day').toISOString();

const HomeMatchesWidget = () => {
  const [mediaMin1800] = useMediaQuery('(min-width: 1900px)');
  const [mediaMin1700] = useMediaQuery('(min-width: 1700px)');
  const [mediaMin1600] = useMediaQuery('(min-width: 1600px)');
  const [mediaMin1200] = useMediaQuery('(min-width: 1200px)');
  const [mediaMin991] = useMediaQuery('(min-width: 991px)');
  const [mediaMax767] = useMediaQuery('(max-width: 767px)');

  const { matches } = useMatches({
    where: {
      competition: { isPublic: { equals: true } },
      matchDate: {
        gte: sevenDaysAgo,
        lte: fourteenDaysAhead,
      },
    },
    orderBy: { matchDate: OrderDirection.Asc },
    take: 35,
  });

  function isSameDay(day1: string, day2: string) {
    return dayjs(day1).isSame(day2, 'day');
  }

  function returnVisibleSlides() {
    if (mediaMin1800) {
      return 6;
    } else if (mediaMin1700) {
      return 5;
    } else if (mediaMin1600) {
      return 4;
    } else if (mediaMin1200) {
      return 4;
    } else if (mediaMin991) {
      return 3;
    } else {
      return 2;
    }
  }

  const currentSlide = useMemo(() => {
    const todaysIndex = matches?.findIndex((match) => {
      return dayjs(match.matchDate).isSame(dayjs(), 'day');
    });
    if (todaysIndex > 2) {
      return todaysIndex - 2;
    }
    if (todaysIndex === -1) {
      return 0;
    }
    return todaysIndex;
  }, [matches]);

  // sort matches by ascending

  return matches && matches.length > 0 ? (
    <Box p={[2, 5, 10, 10]} position="relative">
      <Carousel
        totalSlides={matches.length}
        visibleSlides={returnVisibleSlides()}
        step={1}
        slideMargin={mediaMax767 ? '5px' : '20px'}
        trayPadding={mediaMax767 ? '0px 120px' : '0'}
        draggable={true}
        currentSlide={currentSlide}
      >
        <Slider>
          {matches.map((match, i) => {
            return (
              <Slide key={match.id}>
                <Box
                  position="relative"
                  _before={{
                    display:
                      i === 0 ||
                      !isSameDay(matches[i - 1].matchDate, match.matchDate)
                        ? 'block'
                        : 'none',
                    content: '" "',
                    position: 'absolute',
                    left: '-5%',
                    height: '100%',
                    width: '1px',
                    background: 'gray.200',
                    transition: 'all 0.3s ease',
                  }}
                >
                  {i === 0 ||
                  !isSameDay(matches[i - 1].matchDate, match.matchDate) ? (
                    <Box
                      p={['0 20px']}
                      height={['40px', '50px', '50px', '50px']}
                      display="flex"
                      alignItems="center"
                      rounded="full"
                      fontWeight="bold"
                      fontSize={['md', 'lg', 'lg', 'lg']}
                      border="1px solid #272727"
                      width="fit-content"
                      className="date-box"
                    >
                      {dayjs(match.matchDate).format('MMM D, YYYY')}
                    </Box>
                  ) : (
                    <Box height={['40px', '50px', '50px', '50px']} />
                  )}

                  <Link
                    rounded="xl"
                    href={`/match/${match.id}`}
                    _hover={{ background: 'blue.50' }}
                    display="block"
                    padding={['10px', '15px', '15px', '15px']}
                    transition="all 0.3s ease"
                  >
                    <Flex
                      borderBottom="1px solid #272727"
                      fontWeight="bold"
                      fontSize={['13px', 'sm', 'md', 'md']}
                      mt={['10px', '25px', '25px', '25px']}
                      justifyContent={'space-between'}
                    >
                      <Box>{returnTimeAnyway(match.matchDate)}</Box>
                      {match.isMatchLive ? (
                        <Badge
                          colorScheme="green"
                          h="20px"
                          display="flex"
                          alignItems="center"
                          px="10px"
                          rounded="full"
                        >
                          Live
                        </Badge>
                      ) : null}
                      <Box>
                        {returnTime(match.matchDate, returnInMatchTime(match))}
                      </Box>
                    </Flex>

                    <Flex justifyContent={'space-between'} pb="15px">
                      <Box>
                        <Flex alignItems="center" pt="20px">
                          <Text
                            fontWeight="bold"
                            fontSize={['sm', 'sm', 'md', 'md']}
                            noOfLines={1}
                            w={['72px', '80px', '140px', '155px']}
                          >
                            {match.homeTeam.teams?.[0].name}
                          </Text>
                          <Image
                            src={
                              SERVER_URL + returnImage(match.homeTeam.teams[0])
                            }
                            boxSize={['25px', '25px', '35px', '35px']}
                            fallbackSrc={ThemeIcon}
                          />
                        </Flex>
                        <Flex alignItems="center" pt="20px">
                          <Text
                            fontWeight="bold"
                            fontSize={['sm', 'sm', 'md', 'md']}
                            noOfLines={1}
                            w={['72px', '80px', '140px', '155px']}
                          >
                            {match.awayTeam.teams?.[0].name}
                          </Text>

                          <Image
                            src={
                              SERVER_URL + returnImage(match.awayTeam.teams[0])
                            }
                            boxSize={['25px', '25px', '35px', '35px']}
                            fallbackSrc={ThemeIcon}
                          />
                        </Flex>
                      </Box>
                      <Box
                        p={[
                          '10px 10px 10px',
                          '10px 10px 10px',
                          '10px 25px 17px',
                          '10px 25px 17px',
                        ]}
                        textAlign="center"
                        margin="10px 0 0"
                        boxShadow="0 0 3px rgba(0,0,0,0.25)"
                        rounded="md"
                      >
                        <Text
                          fontSize="xl"
                          fontWeight="extrabold"
                          color={greenishColor}
                          mt={['0px', '2px', '2px', '2px']}
                        >
                          {match.isMatchLive ||
                          match.isMatchEnded ||
                          match.inMatchTime
                            ? match.homeTeamScore || '0'
                            : '-'}
                        </Text>
                        <Text
                          fontSize="xl"
                          fontWeight="extrabold"
                          color={greenishColor}
                          mt={['13px', '20px', '20px', '20px']}
                        >
                          {match.isMatchLive ||
                          match.isMatchEnded ||
                          match.inMatchTime
                            ? match.awayTeamScore || '0'
                            : '-'}
                        </Text>
                      </Box>
                    </Flex>
                    <Center>
                      <Badge rounded="full" fontSize="xs">
                        {match.competition?.name}
                      </Badge>
                    </Center>
                  </Link>
                </Box>
              </Slide>
            );
          })}
        </Slider>

        <Box
          w="fit-content"
          position="absolute"
          top="53%"
          left="10px"
          rounded="full"
          border="1px solid #dadada"
          transition="all 0.3s ease"
          display={['none', 'none', 'inline', 'inline']}
          _hover={{ background: greenishColor, color: 'white' }}
        >
          <ButtonBack>
            <ChevronLeftIcon boxSize={'40px'} />
          </ButtonBack>
        </Box>
        <Box
          w="fit-content"
          position="absolute"
          top="53%"
          right="10px"
          display={['none', 'none', 'inline', 'inline']}
        >
          <ButtonNext>
            <Box
              boxSize="40px"
              rounded="full"
              border="1px solid #dadada"
              transition="all 0.3s ease"
              _hover={{ background: greenishColor, color: 'white' }}
            >
              <ChevronRightIcon boxSize={'40px'} />
            </Box>
          </ButtonNext>
        </Box>
      </Carousel>
    </Box>
  ) : (
    <Box minH={'300px'} />
  );
};

export default HomeMatchesWidget;
